// src/components/home/Home.js
import React, { useEffect, useState } from 'react';
import './Home.css';
import { Link, Element } from 'react-scroll';
import Hero from '../hero/Hero';
import { TypeAnimation } from 'react-type-animation';
import api from '../../api/axiosConfig'; 

const Home = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get("/api/v1/projects");
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const latinText = `I am Eshwaran Krishnan, a dedicated and ambitious rising junior in Computer Engineering at Texas A&M University. Known for my diligence and innovative spirit, 
                    I thrive on solving complex challenges in Semiconductors and Artificial Intelligence. I approach problems with enthusiasm, continually seeking opportunities to 
                    learn and grow in both technical and personal realms. The website is still under maintenance due to bugs, and will be updated soon!`;

  return (
    <div className="home-container">
      <Element name="hero" className="hero-section">
        <video autoPlay loop muted playsInline className="video-background">
          <source src={require('./about-background.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="hero-content">
          <TypeAnimation
            sequence={[
              'Howdy! I am Eshwaran Krishnan.',
              3000,
              'Welcome to my website!',
              3000,
            ]}
            wrapper="span"
            speed={50}
            style={{ fontSize: '2em', display: 'inline-block' }}
            repeat={Infinity}
          />
          <p className="home-subtitle">Explore my projects and learn more about my journey.</p>
          <Link className="scroll-link" to="about" smooth={true} duration={100}>
            Learn More
          </Link>
        </div>
      </Element>

      <Element name="about" className="about-section">
        <div className="about-content">
          <h2>About Me</h2>
          <p className="about-text">{latinText}</p>
          <Link className="scroll-link" to="projects" smooth={true} duration={1000}>
            View Projects
          </Link>
        </div>
      </Element>

      <Element name="projects" className="projects-section">
        <h2>Projects</h2>
        <Hero projects={projects} />
      </Element>
    </div>
  );
};

export default Home;
