import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import api from '../src/api/axiosConfig'; // Adjust path as needed

const LoadingIndicator = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate fetching data from API
    const fetchData = async () => {
      try {
        await api.get('/api/v1/projects');
        // Simulate loading delay
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Stop loading on error
      }
    };

    fetchData();
  }, []);

  if (!isLoading) return null;

  return (
    <div className="loading-container" css={loadingContainerStyle}>
      <div className="loading-content" css={loadingContentStyle}>
        <ClipLoader color={"#36D7B7"} loading={true} css={override} size={100} />
        <p>Connecting to web service (can take up to a minute)</p>
      </div>
    </div>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
`;

const loadingContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const loadingContentStyle = css`
  text-align: center;
`;

export default LoadingIndicator;
