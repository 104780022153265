import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../api/axiosConfig';
import LoadingIndicator from '../../loadingIndicator';

const AuthCallback = () => {
  document.title = "Redirecting..."
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [authData, setAuthData] = useState(null);
  const [parentPageIds, setParentPageIds] = useState([]);

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');

    if (!code) {
      console.error("No code found in the URL.");
      setLoading(false);
      return;
    }

    const fetchAuthData = async () => {
      try {
        // Remove any existing auth data
        localStorage.removeItem('authData');

        // Send request to the server with the code
        const response = await api.post('/api/notion/auth', null, {
          params: { code: String(code) },
        });

        // Store the received auth data in local storage
        const accessToken = response.data.access_token;
        setAuthData(response.data);
        localStorage.setItem('authData', JSON.stringify(response.data));

        // Fetch Notion pages using the access token
        const pagesResponse = await api.get('/api/notion/fetch-notion-pages', {
          params: { apiKey: accessToken },
        });

        const pages = pagesResponse.data;
        setParentPageIds(pages);

        // Store the parent page IDs in local storage or any required state
        localStorage.setItem('parentPageIds', JSON.stringify(pages));

        setLoading(false);

        // Redirect after a delay
        setTimeout(() => {
          window.location.replace('/notesdev');
        }, 3000); // 3-second delay
      } catch (error) {
        console.error("Authorization failed:", error);
        setLoading(false);
      }
    };

    fetchAuthData();
  }, [location.search]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (authData) {
    return (
      <div>
        <h1>Authorization Successful!</h1>
        <p>You will be redirected shortly...</p>
        <div>
          <h2>Authorization Details</h2>
          <p><strong>Access Token:</strong> {authData.access_token}</p>
          <p><strong>Token Type:</strong> {authData.token_type}</p>
          <p><strong>Bot ID:</strong> {authData.bot_id}</p>
          <p><strong>Workspace Name:</strong> {authData.workspace_name}</p>
          <p><strong>Workspace Icon:</strong> <img src={authData.workspace_icon} alt="Workspace Icon" /></p>
          <p><strong>Workspace ID:</strong> {authData.workspace_id}</p>
          <p><strong>Owner Name:</strong> {authData.owner.user.name}</p>
          <p><strong>Owner Email:</strong> {authData.owner.user.person.email}</p>
          <p><strong>Owner Avatar:</strong> <img src={authData.owner.user.avatar_url} alt="Owner Avatar" /></p>
          <p><strong>Duplicated Template ID:</strong> {authData.duplicated_template_id || 'N/A'}</p>
          <p><strong>Request ID:</strong> {authData.request_id}</p>
          <p><strong>Parent Page IDs:</strong> {parentPageIds.join(', ')}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>Authorization Failed</h1>
      <p>Please try again.</p>
    </div>
  );
};

export default AuthCallback;
