import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Authorize from './components/authorize/Authorize';
import AuthCallback from './components/authcallback/AuthCallback';
import Layout from './components/layout';
import Home from './components/home/Home';
import ProjectDetails from './components/ProjectDetails';
import LoadingIndicator from './loadingIndicator';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Preserve the code parameter in the navigation to the callback route
      navigate(`/notesdev/auth/notion/callback?code=${code}`, { replace: true });
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000); // Simulated delay
  }, [navigate]);

  return (
    <div className="App">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/projects" element={<ProjectDetails />} />
            <Route path="/notesdev" element={<Authorize />} />
            <Route path="/notesdev/auth/notion/callback" element={<AuthCallback />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
