// src/components/Authorize.js
import React, { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation'; // Import TypeAnimation
import './Authorize.css'; // Import the CSS file
import axios from 'axios';
import { FaPaperPlane } from 'react-icons/fa';

const Authorize = () => {
  document.title = "NotesDev";
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authData, setAuthData] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [conversation, setConversation] = useState([]); // Track conversation

  const authMessage = "Welcome to NotesDev! To get started, please authorize access to your Notion workspace.";
  const authorizedMessage = "Welcome back! You are authorized. Please provide a text prompt below:";

  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      setAuthData(JSON.parse(storedAuthData));
      setIsAuthorized(true);
    }
  }, []);

  const handleAuth = () => {
    const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
    const redirectUri = 'https://eshwaran-krishnan.live/notesdev/auth/notion/callback';
    const authUrl = `https://api.notion.com/v1/oauth/authorize?client_id=${clientId}&response_type=code&owner=user&redirect_uri=${encodeURIComponent(redirectUri)}`;
    window.location.href = authUrl;
  };

  const handleSubmit = async () => {
    if (!authData || !prompt) return;

    setConversation([...conversation, { text: prompt, type: 'user' }]); // Add user prompt to conversation

    try {
      const storedParentPageIds = localStorage.getItem('parentPageIds');
      if (!storedParentPageIds) {
        throw new Error("No parent page IDs found in local storage.");
      }

      const parentPageIds = JSON.parse(storedParentPageIds);

      const requestBody = {
        api_key: authData.access_token, // Use access_token from authData
        parent_page_ids: parentPageIds, // Parsed page IDs array
        question: prompt // User-entered prompt
      };

      // Send POST request to ngrok tunnel
      const response = await axios.post('https://e297-2607-fb90-922e-c117-a89b-8b53-b3a1-f661.ngrok-free.app/send-to-notion', requestBody);

      if (response.data === null) {
        setConversation([...conversation, { text: "Done!", type: 'bot' }]); // Add bot response to conversation
      } else {
        throw new Error("Unexpected response from server.");
      }
    } catch (error) {
      setConversation([...conversation, { text: `Error: ${error.message}`, type: 'bot' }]); // Add error message to conversation
    }
  };

  return (
    <div className="container">
      {!isAuthorized ? (
        <>
          <div className="chat-bubble bot-bubble">
            <TypeAnimation
              sequence={[authMessage, 1000]}
              wrapper="p"
              speed={50}
              style={{ display: 'inline-block' }}
            />
          </div>
          <div className="chat-bubble user-bubble">
            <button onClick={handleAuth}>Authorize with Notion</button>
          </div>
        </>
      ) : (
        <>
          {conversation.map((entry, index) => (
            <div
              key={index}
              className={`chat-bubble ${entry.type}-bubble`}
            >
              {entry.text}
            </div>
          ))}
          <div className="footer">
            <textarea
              placeholder="Enter your prompt here..."
              rows="2"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            ></textarea>
            <button className="send-button" onClick={handleSubmit}>
              <FaPaperPlane />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Authorize;